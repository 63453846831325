import { Box } from '@mui/material'
import React from 'react'
import ArticleOrders from './ArticleOrders'

export default function Article() {
  return (
   <Box>

    <ArticleOrders/>
   </Box>
  )
}
