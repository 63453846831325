import React, { useContext, createContext } from "react";
import axios from 'axios';
// const BASE_URL = "https://link.inposizione.it"
const BASE_URL = process.env.REACT_APP_URL
// const BASE_URL = "https://inposizione.thewebconcept.com"


const FilterContext = createContext()
export default function FilterContextProvider({ children }) {
    const [minPrice, setMinPrice] = React.useState('')
    const [maxPrice, setMaxPrice] = React.useState('')

    const [cancel, setCancel] = React.useState(true)
    const [cancelSrch, setCancelSrch] = React.useState(true)
    const [daValue, setDaValue] = React.useState([0, 100])
    const [zaValue, setZaValue] = React.useState([0, 100])
    const [za, setZa] = React.useState(0)
    // const [zaMax, setZaMax] = React.useState(100)
    const [da, setDa] = React.useState(0)
    // const [daMax, setDaMax] = React.useState(100)
    const [accept, setAccept] = React.useState('')
    const [filterdPro, setFilteredPro] = React.useState([])
    const [activeFilter, setActiveFilter] = React.useState([])
    const [searchName, setSearchName] = React.useState('')
    const [gnews, setGnews] = React.useState('')
    const [category, setCategory] = React.useState('')
    const [theme, setTheme] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [status, setStatus] = React.useState('')
    const [loadingPro,setLoadingPro]=React.useState(false)

    React.useEffect(() => {
        getProducts()

    }, [])

    React.useEffect(() => {
        // console.log(category)
        // console.log(filterdPro)
        // console.log()
    }, [category, filterdPro])



    // https://thewebconcept.com/inposizione/admin/api/index.php?type=filter&dafrom=0&dato=64&zafrom=0&zato=82&gnews=si&category=moda&theme=adult&priceFrom=100&priceto=600
    const getProducts = async () => {
        setLoadingPro(true)
        const userData = JSON.parse(window.localStorage.getItem('user'));

        await axios.get(`${BASE_URL}/admin/api/index.php?type=networklist&user_id=${userData.user_id}`)
            .then(function (response) {
                // handle success
                // console.log(response);
                if (response.data !== null) {

                    setFilteredPro(response.data)
                    setLoadingPro(false)

                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }


    //set search by Filter
    // const handleFilter = (product) => {
    //     localStorage.setItem('filter', JSON.stringify({
    //         dafrom: daValue[0], daTo: daValue[1], zaFrom: zaValue[0], zaTo: zaValue[1], gnews: gnews, category: category, theme: theme, priceFrom: minPrice, priceTo: maxPrice
    //     }))
    //     setCancel(true)
    //     setFilteredPro(

    //     )

    // }

    const handleFilter = async () => {
        setLoading(true)
        // await axios.get(`
        // https://thewebconcept.com/inposizione/admin/api/index.php?type=filter&dafrom=0&dato=64&zafrom=0&zato=82&gnews=si&category=moda&theme=adult&priceFrom=100&priceto=600
        // `)
        await axios.get(`
         ${BASE_URL}/admin/api/index.php?type=filter&dafrom=${daValue[0]}&dato=${daValue[1]}&zafrom=${zaValue[0]}&zato=${zaValue[1]}&gnews=${gnews}&category=${category}&theme=${theme}&pricefrom=${minPrice}&priceto=${maxPrice}
        `)
            .then(function (response) {
                // handle success
                console.log(response.data);
                if (response.data == null) {

                    setStatus(response.data)
                }
                if (response.data !== null) {
                    setFilteredPro(response.data)
                    setActiveFilter(response.data)
                }
                setLoading(false)
            })
            .catch(function (error) {
                // handle error
                setLoading(false)
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }



    //HANDLE RESET FILTER
    const resetFilter = () => {
        // alert('helo')
        setDa('')
        setZa('')
        setAccept('')
        setSearchName('')
        setGnews('')
        setCategory('')
        setTheme('')

    }


    return (<FilterContext.Provider value={{
        cancel, setCancel, setAccept, handleFilter, zaValue, setZaValue, daValue, setDaValue,
        filterdPro, setFilteredPro, cancelSrch, setCancelSrch, searchName, setSearchName, gnews,
        setGnews, setMinPrice, setMaxPrice, category, setCategory, resetFilter, minPrice, maxPrice, setTheme, theme, loading, activeFilter, status, setStatus,loadingPro
    }}>
        {children}
    </FilterContext.Provider>)

}
export const useFilterContext = () => {
    return useContext(FilterContext)
}


// product.filter((item) =>
// item.pro_da >= daValue[0] && item.pro_da <= daValue[1]
// && item.pro_za >= zaValue[0] && item.pro_za <= zaValue[1]
// && item.pro_price >= minPrice && item.pro_price <= maxPrice
// || gnews=== item.pro_gnews
// && item.pro_category.some((pro) => pro === category)
// || item.pro_theme === theme


// item.pro_da >= daValue[0] && item.pro_da <= daValue[1]
// || item.pro_za >= zaValue[0] && item.pro_za <= zaValue[1]
// || gnews === item.pro_gnews
// || item.pro_price >= minPrice && item.pro_price <= maxPrice
// || item.pro_theme === theme
// || item.pro_category.some((pro) => pro === category)
// || category === item.pro_category

// )