import React from 'react'
import { Box } from '@mui/system'
import Sales from './Sales'
import Graph1 from './Data visiualization/Graph1'
import { useUserOrderContext } from 'context/UserOrderContext'
import Spinner from 'components/Progress Bar/Spinner'

export default function Home() {
    const { dashbaordLoading } = useUserOrderContext()
    return (

        <>{dashbaordLoading ?
            <Spinner />

            : <Box >

                <Sales />

                <Graph1 />
            </Box>
        }
        </>
    )
}
