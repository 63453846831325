import React from 'react'
import { Box } from '@mui/system'
import { Grid, Paper, Typography } from '@mui/material'
// import SaleChart from '../../../Components/Charts/SaleChart'
import SaleOverview from 'components/Charts/SaleOverview'
import SaleChart from 'components/Charts/SaleChart'
import { useTheme } from '@mui/material'
import Sales from '../../../DashboardComponents/Sales'

export default function Graph1() {

    const theme = useTheme()
    const styles = {
        text: {
            fontSize: 12,
            color: theme.palette.text.secondary,
            textAlign: 'center',
            marginBottom: -1,
            fontWeight: 'bold'

        },
        box: {
            width: '5rem'
        }
    }
    return (
        <Grid container alignItems={'center'} spacing={5} sx={{ mt: 1 }}>




            <Grid item xs={12} sm={7} lg={7}>

                <Paper elevation={3} sx={{ height: '15.5rem', borderRadius: 5, p: 1 }}>
                    {/* <Typography sx={{ fontWeight: 'bold' }}>
                        Sales Overview
                    </Typography> */}
                    {/* <SaleOverview /> */}
                    <SaleChart />
                </Paper>

            </Grid>

            <Grid item xs={12} sm={5} lg={5}>

                <Paper elevation={3} sx={{
                    height: '15rem', borderRadius: 5, p: 2,
                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                }}>
                    <Sales />
                </Paper>

            </Grid>






        </Grid>
    )
}
