import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useCartContext } from 'context/CartContext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Typography } from '@mui/material';
import swal from 'sweetalert';
import { makeStyles } from '@mui/styles';



const Details = React.forwardRef(({ props }, ref) => {
    const [open, setOpen] = React.useState(false);
    const { currentItem } = useCartContext()
    const { t } = useTranslation()


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            overflow: 'hidden',
        },
    }));
    const classes = useStyles();




    const array = [
        `Link Follow `,
        `Numero di link consentiti: 2/3`,
        `Consentiamo link a fonti autorevoli`,
        `Immagine principale dell'articolo: Obbligatorio`,
        `Numero immagini nel contenuto: 1/3`,
        `Lughezza minima articolo: 500 Lughezza massima articolo: nessuna`,
        `Non limitiamo la lunghezza del contenuto`,
        `L'articolo deve avere un immagine`,
        `L'articolo può essere di natura pubblicitaria.`,
        `Durata Articolo: a vita del sito`,
    ]

    const triggerAlert = () => {
        swal({
            title: "Text Copied!",
            text: " ",
            icon: "success",
            timer: 1000,
            buttons: false
        })
    }
    // console.log(currentItem.pro_additionalnotes)

    return (
        <div style={{ position: 'relative' }}>
            <Button variant="outlined" sx={{ display: 'none' }} onClick={handleClickOpen} ref={ref}>
                Open alert dialog
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <IconButton color='primary'
                    sx={{ position: 'absolute', right: 0, top: 10 }}
                    onClick={() => {
                        triggerAlert()
                        navigator.clipboard.writeText(currentItem.pro_examplepublication)
                    }}
                >
                    <ContentCopyIcon />
                </IconButton>
                <DialogTitle id="alert-dialog-title">
                    {t("publication_example")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='copy' className={classes.root}>
                        {currentItem.pro_examplepublication}
                    </DialogContentText>
                </DialogContent>
                <DialogTitle id="alert-dialog-title">
                    {t("additional_notes")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        {currentItem.pro_additionalnotes === '' ? array.map((text) => {
                            return <Typography> {text}</Typography>

                            }) : <div style={{ whiteSpace: 'pre-wrap'}}>{currentItem.pro_additionalnotes}</div>
                      
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose}>Disagree</Button> */}
                    <Button onClick={handleClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
})

export default Details;