import * as React from 'react';
import * as yup from "yup";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useCartContext } from 'context/CartContext';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';



const theme = createTheme();

export default function CartInputForm() {
    const { t } = useTranslation()
    const { setIsSubmited, setOpen, addToCart, currentItem } = useCartContext()
    const [itemReview, setItemReview] = React.useState('')
    //Yup Schema
    const schema = yup.object({

        destinationLink: yup.string().required("Name required"),
        anchorText: yup.string().required("City required"),
        // furtherForm: yup.string().trim().required("City required")
    }).required();

    let currentDate = new Date();
    const presentDate = currentDate.getMonth() + 1 + '/' + currentDate.getDate() + '/' + currentDate.getFullYear()
    const [value, setValue] = React.useState(dayjs(`${presentDate}`));

    const handleChange = (newValue) => {
        // console.log(newValue.$d)
        setValue(newValue);
    };
    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = data => {
        // console.log({ currentItem:currentItem, userFormData: {...data,review:itemReview,date:value} })
        addToCart({ ...currentItem, userFormData: { ...data, review: itemReview, expirydate: value.$d } })
        // console.log({ ...currentItem, userFormData: {...data,review:itemReview,expirydate:value.$d} })
        setIsSubmited(true)
        setOpen(false)
    }


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" >
                <CssBaseline />
                <Box
                    sx={{
                        // marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >


                    <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
                        <Grid container spacing={2} sx={{ height: '25rem' }}>



                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="destinationLink"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="destinationLink"
                                            // required
                                            fullWidth
                                            id="destinationLink"
                                            label={t("destination_link")}
                                            defaultValue=""
                                            error={errors.destinationLink}
                                        // helperText={errors.destinationLink?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="anchorText"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="anchorText"
                                            // required
                                            fullWidth
                                            id="anchorText"
                                            label={t("anchor_text")}
                                            defaultValue=""
                                            error={errors.anchorText}
                                        // helperText={errors.destinationLink?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="furtherForm"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="furtherForm"
                                            // required
                                            fullWidth
                                            id="furtherForm"
                                            multiline
                                            rows={4}
                                            label={t("further_info")}
                                            defaultValue=""
                                        // error={errors.furtherForm}
                                        // helperText={errors.destinationLink?.message}
                                        />}
                                />
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker

                                        minDate={new Date()}
                                        label={t("expire_date")}
                                        inputFormat="MM/DD/YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormLabel id="demo-row-radio-buttons-group-label">{t('item_review')}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="review"
                                    onChange={(e) => setItemReview(e.target.value)}

                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="SI" />
                                    <FormControlLabel value="no" control={<Radio />} label="NO" />


                                </RadioGroup>
                            </Grid>








                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 5, mb: 2 }}
                        >
                            Fatto
                        </Button>

                    </form>
                </Box>
            </Container>
        </ThemeProvider>
    );
}