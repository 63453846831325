import React from 'react'
import {
    Routes,
    Route,

} from "react-router-dom";
import ProtectedRoute from 'Protected Routes/UserRoute';
import UserDashboard from './Dashboard/DashboardScreens/UserDashboard/UserDashboard';
import Orders from './Dashboard/DashboardScreens/Orders/Orders';
import UserCart from './Dashboard/DashboardScreens/UserCart';
import Products from './Dashboard/DashboardScreens/Product/Products';
import Settings from './Dashboard/DashboardScreens/Settings/Settings';
import CheckOut from './Dashboard/DashboardScreens/Checkout/ChekOut'
import Profile from './Dashboard/DashboardScreens/Profile/Profile';
import Home from './Dashboard/DashboardScreens/Home/Home';
import Article from './Dashboard/DashboardScreens/Article/Article';
import EditorScrren from './Dashboard/DashboardScreens/EditorScreen/EditorScrren';
import CompletedOrders from './Dashboard/DashboardScreens/Orders/CompletedOrders';
import AddNetwork from './Dashboard/DashboardScreens/Product/AddNetwork';

export default function ScreensNavigator() {

    return (
        <div>
            {/* <TopNavbar /> */}
            <Routes path='/'>



                <Route index element={<ProtectedRoute Component={Home} />} />
                <Route path='orders' element={<ProtectedRoute Component={Orders} />} />
                {/* <Route path='userCart' element={<ProtectedRoute Component={UserCart} />} /> */}
                <Route path='products' element={<ProtectedRoute Component={Products} />} />
                <Route path='settings' element={<ProtectedRoute Component={Settings} />} />
                {/* <Route path='checkOut' element={<ProtectedRoute Component={CheckOut} />} /> */}
                <Route path='profile' element={<ProtectedRoute Component={Profile} />} />
                <Route path='article' element={<ProtectedRoute Component={Article} />} />
                <Route path='/editor' element={<ProtectedRoute Component={EditorScrren} />} />
                <Route path='/addNetwork' element={<ProtectedRoute Component={AddNetwork} />} />
                <Route path='/completedOrders' element={<ProtectedRoute Component={CompletedOrders} />} />

                <Route
                    path="*"
                    element={
                        <div>
                            <h2>404 Page not found</h2>
                        </div>}
                />

            </Routes>


        </div>
    )
}
