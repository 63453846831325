import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from "react-i18next";
import ModeIcon from '@mui/icons-material/Mode';
import { useUserOrderContext } from 'context/UserOrderContext';
// import OrderDetailPopUp from '../../Components/PopUp/DetailsPopUp';
import { IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useArticleContext } from 'context/UserArticle';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArticlePopUp from 'components/PopUp/ArticlePopUp';
import EditPopUp from 'components/PopUp/EditPopUp';
//Creat data for row
function createData(serial, order_item_id, name_domain, anchorText, revision, expirydate, writer_sts, writing_text, revisionnote, destinationLink,) {
  return { serial, order_item_id, name_domain, anchorText, revision, expirydate, writer_sts, writing_text, revisionnote, destinationLink, };
}


// const userData = JSON.parse(window.localStorage.getItem('user'));

export default function ArticleOrders() {

  const { articles, currentArticle, setCurrentArticle, ordersLoading } = useArticleContext()
  // const {setCurrentArticle}=useCartContext()
  const { t } = useTranslation()
  const navigate = useNavigate()

  //RELOAD API ON CHANGE ANY VALUE


  //GENERATE ROWS FOR TABLE
  const rows =
    // articles.filter((order => order.writer_sts !== 'approvedarticle')).map((item, i) => {
    articles.map((item, i) => {

      return createData(i, item.order_item_id, item.name_domain, item.anchorText, item.revision, item.expirydate,
        item.writer_sts, item.writing_text, item.revisionnote, item.destinationLink,)

    })
  // console.log(articles)

  //DETAIL MODAL REF
  const detailRef = React.useRef(null)
  const handleDetailRef = () => {
    detailRef.current.click()
  }
  const editRef = React.useRef(null)
  const handleEditRef = () => {
    editRef.current.click()
  }

  const edit = () => {

  }

  return (
    <>{articles.length < 1 ?

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>


        <Typography variant='subtitle1' align='center' sx={{ marginTop: '15rem' }}>
          {t('empty')}
        </Typography>
      </Box>



      : <div>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>

          <CircularProgress sx={{ display: `${ordersLoading ? 'block' : 'none'}` }} />
        </Box>


        <TableContainer component={Paper} sx={{ display: `${!ordersLoading ? 'block' : 'none'}` }}>

          <Table sx={{ worder_item_idth: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('order_number')}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('web_site')}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('domain_name')}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('order_start_date')}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("order_status")}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("view_article")}</TableCell>
                {/* <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("add_note")}</TableCell> */}
                {/* <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("amount")}</TableCell> */}
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("note")}</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >

                  <TableCell align="center">{row.order_item_id}</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 14 }}>
                    <a href={`https://${row.name_domain}`} target="_blank">{row.name_domain}</a>
                  </TableCell>
                  <TableCell align="center">{row.anchorText}</TableCell>
                  <TableCell align="center">{row.expirydate}</TableCell>


                  <TableCell align="center" sx={{ width: '12rem' }}>
                    <div style={{
                      padding: 5, backgroundColor:
                        `${row.writer_sts === 'pendingarticle' ? '#fff75e'
                          : row.writer_sts === "approvedarticle" ? "#b2ff9e"
                            : row.writer_sts === '' ? '#fff75e'
                            : "#f38375"}`
                      , borderRadius: '12px', fontSize: 12, fontWeight: 'bold'
                    }}>

                      {t(row.writer_sts!=''?row.writer_sts:"pendingarticle")}
                      {/* {row.writer_sts} */}
                    </div>
                  </TableCell>

                  <TableCell align="center">
                    <IconButton variant="contained"
                      // disabled={row.writer_sts === 'approvedarticle'}
                      onClick={() => {
                        navigate('/editor', { state: { data: row } })
                      }
                      }
                    >
                      <VisibilityIcon color='primary' />
                    </IconButton>
                  </TableCell>

                  {/* <TableCell align="center">
                    <IconButton variant="contained"
                      onClick={() => {

                        handleEditRef()
                        
                      }
                      }
                    >

                      <EditIcon color='primary' />
                    </IconButton>

                  </TableCell> */}
                  {/* <TableCell align="center" sx={{ color: 'primary' }}>{row.total
                  }</TableCell> */}

                  <TableCell align="center">
                    <IconButton variant="contained"
                      // disabled={row.writer_sts === 'approvedarticle'}
                      onClick={() => {

                        handleDetailRef()
                        setCurrentArticle(row.revisionnote)
                        // console.log(row)

                      }
                      }
                    >

                      <StickyNote2Icon color='primary' />
                    </IconButton>
                  </TableCell>





                </TableRow>
              ))}
            </TableBody>
          </Table>

        </TableContainer>

        <ArticlePopUp ref={detailRef} />
        <EditPopUp ref={editRef} />
      </div>
    }
    </>
  );
}

