import * as React from 'react';
import * as yup from "yup";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useUserAuth } from 'context/UserAuthContext';
import { LoadingButton } from '@mui/lab';
import swal from 'sweetalert'
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useProductContext } from 'context/ProductsContext';
import { useOrderContext } from 'context/OrderContext';
// import { useProductContext } from 'context/ProductsContext';
// import LogoIcon from '../../assets/svg/Inposizione-Logo.png'
import { useTranslation } from "react-i18next";




const theme = createTheme();

export default function AddNetworkForm({ product, setProduct }) {
    const { t } = useTranslation();
    const { categories } = useProductContext()
    const [names, setNames] = React.useState([])
    const [themes, setThemes] = React.useState([])
    const { createOrder, isLoading, orderResponse, setOrdersResponse } = useOrderContext()
    const { setProducts, products } = useProductContext()
    // const arrayOfCategories = categories.categories != null && categories.categories.map(obj => obj.categories_name)
    // console.log(categories)
    // const arr = Object.entries(categories.categories).map(([categories_id, categories_name]) => categories_name)
    // console.log(categories.categories.map(obj => obj.categories_name))
    React.useEffect(() => {
        setNames(categories.categories != null && categories.categories.map(obj => obj.categories_name))
        setThemes(categories.themes != null && categories.themes.map(obj => obj.theme_name))
    }, [categories])
    //Yup Schema
    const schema = yup.object({
        // backlink: yup.string().trim().min(6, `${t("short_backlink")}`).required(`${t("backlink_required")}`),
        // pro_gnews: yup.string().trim().oneOf([yup.ref('backlink'), null], `${t('backlink_match')}`),
        // theme: yup.string().required(`${t("theme_required")}`),
        // pro_gambling: yup.string().trim().required(`${t("gambling_required")}`),
        pro_ip: yup.string().required(`${t("ip_required")}`),
        pro_name: yup.string().trim().required(`${t("name_required")}`),
        pro_da: yup.string().trim().required(`${t("da_required")}`),
        pro_za: yup.string().trim().required(`${t("pro_za_required")}`),
        pro_backlink: yup.string().trim().required(`${t("backlink_required")}`),
        pro_additionalnotes: yup.string().trim().required(`${t("additionNote_required")}`),
        pro_note: yup.string().trim().required(`${t("pro_note_required")}`),
        pro_redazione: yup.string().trim().required(`${t("redizione_required")}`),
        pro_purchase: yup.string().trim().required(`${t("purchage_rate_required")}`),
        acceptedArgument: yup.string().trim().required(`${t("acceptedArgument_required")}`),
        pro_examplepublication: yup.string().trim().required(`${t("pro_examplepublication_required")}`),
        // pro_price: yup.string().trim().required(`${t("sale_rate_required")}`),
        // pro_gnews: yup.string().trim().required(`${t("pro_gnews_required")}`),
        // category: yup.string().required(`${t("category_required")}`),
    }).required();


    const navigate = useNavigate()
    const [category, setCategory] = React.useState([]);
    const [selectTheme, setSelectTheme] = React.useState([])
    const [pro_gnews, setPro_gnews] = React.useState('')
    const [pro_gambling, setPro_gambling] = React.useState('')


    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)

    });
    const onSubmit = data => {
        // console.log({ ...data, pro_category: category, pro_theme: selectTheme })
        // const pro_theme=JSON.stringify(selectTheme)
        // const pro_category=JSON.stringify(category)
        setProduct({ ...data, pro_gnews: pro_gnews, pro_category: category, pro_theme: selectTheme,pro_gambling:pro_gambling })
        createOrder({ ...data, pro_gnews: pro_gnews, pro_category: category, pro_theme: selectTheme,pro_gambling:pro_gambling })


    }

    //gender and ip section
    if (orderResponse.is_sts === 1) {
        swal(
            {
                title: `${t("success")}`,
                text: `${orderResponse.msg}`,
                icon: 'success',
                //  button:'ok'
            }
        )
        setProducts([...products, product])
        setOrdersResponse('')
        navigate('/products')
    }
    if (orderResponse.is_sts === 0) {
        swal(
            {
                title: `${t("error")}`,
                text: `${orderResponse.msg}`,
                icon: 'error',
                //  button:'ok'
            }
        )
        setOrdersResponse('')
    }


    const ITEM_HEIGHT = 45;
    const ITEM_PADDING_TOP = 6;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.0 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setCategory(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleThemeChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectTheme(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    function getStyles(name, category, theme) {
        return {
            fontWeight:
                category.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="lg">
                <CssBaseline />

                <Box
                    sx={{

                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 5
                        , marginBottom: '2rem',
                        // height:'10rem'
                    }}
                >

                    <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 0 }}>
                        <Typography variant='h6' sx={{mb:1}} align='center'>
                            {t('add_network')}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Controller
                                    name="pro_name"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="pro_name"
                                            // required
                                            fullWidth
                                            id="pro_name"
                                            // label={t("sito_web")}
                                            label='Add Network'
                                            defaultValue=""
                                            error={errors.pro_name}
                                            helperText={errors.pro_name?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Controller
                                    name="pro_ip"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="ip"
                                            label={t("ip")}
                                            name="pro_ip"

                                            defaultValue=""
                                            error={errors.pro_ip}
                                            helperText={errors.pro_ip?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                                <Controller
                                    name="pro_da"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="da"
                                            label={t('da')}
                                            inputProps={{ max: 100 }}
                                            name={t("da")}
                                            onChange={(event) => {
                                                if (event.target.value <= 100) {
                                                    field.onChange(event);
                                                }
                                            }}
                                            type='number'
                                            defaultValue=""
                                            error={errors.pro_da}
                                            helperText={errors.pro_da?.message}
                                        />} />
                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                                <Controller
                                    name="pro_za"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            id="pro_za"
                                            label={t("pro_za")}
                                            name="pro_za"
                                            type='number'
                                            onChange={(event) => {
                                                if (event.target.value <= 100) {
                                                    field.onChange(event);
                                                }
                                            }}
                                            defaultValue=""
                                            error={errors.pro_za}
                                            helperText={errors.pro_za?.message}
                                        />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Controller
                                    name="pro_backlink"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="pro_backlink"
                                            label={t("backlink")}
                                            type='number'
                                            id="backlink"
                                            autoComplete="new-backlink"
                                            defaultValue=""
                                            error={errors.pro_backlink}
                                            helperText={errors.pro_backlink?.message}
                                        />}
                                />
                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <InputLabel id="demo-simple-select-label">{t("pro_gnews")}</InputLabel>
                                    <Select
                                        labelId="elect-label"
                                        id="demo-simple"
                                        // value={gnews}
                                        label="gnews"
                                        onChange={(e) => setPro_gnews(e.target.value)}
                                    >
                                        <MenuItem value={'si'}>Si</MenuItem>
                                        <MenuItem value={'no'}>No</MenuItem>

                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <InputLabel id="demo-simple-select-label">{t("gambling")}</InputLabel>
                                    <Select
                                        labelId="elect-label"
                                        id="demo-simple"
                                        // value={gnews}
                                        label="gnews"
                                        onChange={(e) => setPro_gambling(e.target.value)}
                                    >
                                        <MenuItem value={'si'}>Si</MenuItem>
                                        <MenuItem value={'no'}>No</MenuItem>

                                    </Select>
                                </FormControl>

                            </Grid>
                            {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Controller
                                    name="pro_gnews"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            fullWidth
                                            name="pro_gnews"
                                            label={t("pro_gnews")}
                                            type="backlink"
                                            id="pro_gnews"
                                            autoComplete="new-backlink"
                                            defaultValue=""
                                            error={errors.pro_gnews}
                                            helperText={errors.pro_gnews?.message}
                                        />}
                                />
                            </Grid> */}

                            {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Controller
                                    name="pro_gambling"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="pro_gambling"
                                            label={t("gambling")}
                                            id="gambling"
                                            autoComplete="new-backlink"
                                            defaultValue=""
                                            error={errors.pro_gambling}
                                            helperText={errors.pro_gambling?.message}
                                        />}
                                />
                            </Grid> */}

                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Controller
                                    name="pro_redazione"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="pro_redazione"
                                            label={t("pro_redazione")}
                                            type="backlink"
                                            id="pro_redazione"
                                            autoComplete="new-backlink"
                                            defaultValue=""
                                            error={errors.pro_redazione}
                                            helperText={errors.pro_redazione?.message}
                                        />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Controller
                                    name="acceptedArgument"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            multiline
                                            maxRows={4}
                                            name="acceptedArgument"
                                            label={t("acceptedArgument")}
                                            type="backlink"
                                            id="acceptedArgument"
                                            autoComplete="new-backlink"
                                            defaultValue=""
                                            error={errors.acceptedArgument}
                                            helperText={errors.acceptedArgument?.message}
                                        />}
                                />
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                                <Controller
                                    name="pro_purchase"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="pro_purchase"
                                            label={t("pro_purchase")}
                                            type="number"
                                            id="pro_purchase"
                                            autoComplete="new-backlink"
                                            defaultValue=""
                                            error={errors.pro_purchase}
                                            helperText={errors.pro_purchase?.message}
                                        />}
                                />
                            </Grid>
                            {/* 
                            <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                                <Controller
                                    name="pro_price"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="pro_price"
                                            label={t("pro_price")}
                                            type="number"
                                            id="pro_price"
                                            autoComplete="new-backlink"
                                            defaultValue=""
                                            error={errors.pro_price}
                                            helperText={errors.pro_price?.message}
                                        />}
                                />
                            </Grid> */}

                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Controller
                                    name="pro_note"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            multiline
                                            rows={4}
                                            name="pro_note"
                                            label={t("pro_note")}
                                            type="backlink"
                                            id="pro_note"
                                            autoComplete="new-backlink"
                                            defaultValue=""
                                            error={errors.pro_note}
                                            helperText={errors.pro_note?.message}
                                        />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Controller
                                    name="pro_examplepublication"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            multiline
                                            rows={4}
                                            name="pro_examplepublication"
                                            label={t("publication")}
                                            type="backlink"
                                            id="pro_examplepublication"
                                            autoComplete="new-backlink"
                                            defaultValue=""
                                            error={errors.pro_examplepublication}
                                            helperText={errors.pro_examplepublication?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Controller
                                    name="pro_additionalnotes"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            multiline
                                            rows={4}
                                            name="pro_additionalnotes"
                                            label={t("pro_additionalnotes")}
                                            type="backlink"
                                            id="pro_additionalnotes"
                                            autoComplete="new-backlink"
                                            defaultValue=""
                                            error={errors.pro_additionalnotes}
                                            helperText={errors.pro_additionalnotes?.message}
                                        />}
                                />
                            </Grid>







                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>


                                <FormControl sx={{ width: '100%', p: 0 }}>
                                    <InputLabel id="demo-multiple-chip-label">{t('category')}</InputLabel>
                                    <Select

                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={category}
                                        onChange={handleChange}
                                        // helperText={errors.category?.message}
                                        // error={errors.category}
                                        input={<OutlinedInput
                                            id="select-multiple-chip" label={t('category')} />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {names.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                style={getStyles(name, category, theme)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}


                                    </Select>

                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <FormControl sx={{ width: '100%', p: 0 }}>
                                    <InputLabel id="demo-multiple-chip-label">{t('theme')}</InputLabel>
                                    <Select

                                        labelId="demo-multiple"
                                        id="demo"
                                        multiple
                                        value={selectTheme}
                                        onChange={handleThemeChange}

                                        input={<OutlinedInput
                                            id="select-multiple" label={t('theme')} />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {themes.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                style={getStyles(name, selectTheme, theme)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                        <LoadingButton
                            loading={isLoading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2 }}
                        >
                            {t("addNetwork")}
                        </LoadingButton>


                        {/* <Box sx={{ fontSize: 14, textAlign: 'center', marginTop: 4 }}>

                            <Link to="/landing"  >
                                {t("back_to_home")}
                            </Link>
                        </Box> */}
                    </form>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

