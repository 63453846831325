import React, { useContext, createContext } from "react";
import axios from 'axios';

// const BASE_URL="https://inposizione.thewebconcept.com"
// const BASE_URL="https://link.inposizione.it"
const BASE_URL = process.env.REACT_APP_URL

const OrderContext = createContext()
export default function OrderContextProvider({ children }) {
    const [newOrder, setNewOrder] = React.useState(0)
    const [orderResponse, setOrdersResponse] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [isSubmited, setIsSubmited] = React.useState(false)
    const [currentOrderDetails, setCurrentOrderDetails] = React.useState('')
    const [currentRow, setCurrentRow] = React.useState('')

    React.useEffect(() => {

    }, [])



    // 1:Create New  ORDERS
    const createOrder = async (order) => {
        // console.log(order)
        const user = JSON.parse(localStorage.getItem('user'))
        setIsLoading(true)
        await axios.post(`${BASE_URL}/admin/api/index.php?type=addnetwork&user_id=${user.user_id}`, order)
            .then(function (response) {
                // console.log(response)
                setOrdersResponse(response.data)
                setNewOrder(1)
                setIsLoading(false)
                setIsSubmited(true)

            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false)
                setOrdersResponse('error')

            });
    }

    //FILE UPLOAD 




    return (<OrderContext.Provider value={{
        newOrder, setNewOrder, isLoading, createOrder, orderResponse,
        setOrdersResponse, isSubmited, setIsSubmited, currentOrderDetails, setCurrentOrderDetails,currentRow, setCurrentRow
    }}>
        {children}
    </OrderContext.Provider>)

}
export const useOrderContext = () => {
    return useContext(OrderContext)
}