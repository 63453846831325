import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import OrderFormPagination from 'components/PopUp/ShowOrderDetails/OrderFormPagination';
import { useOrderContext } from 'context/OrderContext';

const OrderDetailPopUp = React.forwardRef(({ props }, ref) => {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation()
    const { currentRow } = useOrderContext()
    // console.log(currentOrder)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen} ref={ref} sx={{ display: 'none' }}>

            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            // sx={{backgroundColour:'red'}}

            >
                <OrderFormPagination />
                <DialogActions>
                    {/* <Button onClick={handleClose}>Disagree</Button> */}
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>

            </Dialog>
        </div>)
}
)
export default OrderDetailPopUp;