import React from 'react'
import { Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './style.css'
import ProfileCard from 'components/Cards/ProfileCard';
import AgencyCard from 'components/Cards/AgencyCard';
import { useAgencyContext } from 'context/AgencyContext';
import { Grid } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import UserForm from 'components/Form/AgencyDetailForm/UserForm';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

export default function UserProfile() {
    const { t } = useTranslation()
    const { agencyData,show, setShow } = useAgencyContext()

    // const agencyData = JSON.parse(window.localStorage.getItem('agency'));

    return (
        <>
            <Paper elevation={3} sx={{
                margin: 'auto', p: 5,
                display: `${!show ? 'block' : 'none'}`
            }}>
                <Grid container alignItems='center'>

                    <Grid xs={12} sm={12} md={12} lg={12} >
                        <ProfileCard />
                    </Grid>


                    {/* <Grid xs={12} sm={12} md={7} lg={7} >
                        <Box sx={{ marginTop: 2, paddingX: 3, minWidth: '10rem' }}>

                            {agencyData !== null ?

                                <AgencyCard /> :
                                <Typography align='center' sx={{ color: 'red' }}>
                                    {t("missing_agency_data")}
                                </Typography>
                            }
                        </Box>
                    </Grid> */}


{/* 
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', paddingRight: 4 }}>

                        <Button variant="contained" size='small'
                            onClick={() => setShow(true)}
                            endIcon={agencyData !== null ? <BorderColorIcon /> : <NoteAddIcon />}>
                            {agencyData !== null ? `${t("edit")}` : `${t("add")}`}
                        </Button>
                    </Box> */}





                </Grid>
            </Paper>

            {/* <Paper elevation={3} sx={{
                margin: 'auto', P: 5,
                display: `${show ? 'block' : 'none'}`
            }}>

                <Grid container alignItems='center'>



                    <Grid xs={12} sm={12} md={12} lg={12} >

                        <UserForm />
                    </Grid>



                </Grid>
            </Paper> */}
        </>

    )
}






// <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>

// <Box sx={{ marginTop: 2 }}>



// <ProfileCard />


// </Box>
// <Box sx={{ marginTop: 2 }}>

// {agencyData !== null ?

// <AgencyCard /> :
// <Typography sx={{marginTop:'8rem'}}>
// {t("missing_agency_data")}
// </Typography>
// }
// </Box>


// </Box>