import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import './style.css'

import { useCartContext } from 'context/CartContext';

//Creat data for row
function createData(serial, id, pro_name, pro_ip, pro_da, pro_price) {
    return { serial, id, pro_name, pro_ip, pro_da, pro_price };
}

//map will place here to map rows


export default function UserCart() {
    const { cartItems, removeCartItem } = useCartContext()
    const { t } = useTranslation()
    const itemsTotal = cartItems.reduce((a, c) => a + (c.pro_price * 1), 0)


    const luchSwal = (id) => {
        Swal({
            title: `${t("sure_to_delete")}`,
            icon: 'warning',
            dangerMode: true,
            buttons: true
        }).then((result) => {

            if (result) {
                removeCartItem(id)
                Swal({
                    icon: 'success',
                    title: `${t("deleted")}`,
                    text: `${t("item_deleted")}`,
                    timer: 1000,
                    buttons: false
                })
            }
            else {
                Swal(

                    { title: `${t("safe_item")}` }
                )
            }
        })
    }

    const rows =
        cartItems.map((item, i) => {
            //return row
            return createData(i, item.id, item.pro_name, item.pro_ip, item.pro_da, item.pro_price)

        })


    return (
        <Paper>

            <TableContainer id='scroll' sx={{ display: `${cartItems.length > 0 ? 'block' : 'none'}`, maxWidth: 620, height: 350 }}>

                <Table stickyHeader sx={{ maxWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: '600', fontSize: 16 }} >ID </TableCell>
                            <TableCell sx={{ fontWeight: '600', fontSize: 16 }} >SITO WEB </TableCell>
                            <TableCell sx={{ fontWeight: '600', fontSize: 16 }} align="center">IP</TableCell>
                            <TableCell sx={{ fontWeight: '600', fontSize: 16 }} align="center">DA</TableCell>
                            <TableCell sx={{ fontWeight: '600', fontSize: 16 }} align="center">{t('price')}</TableCell>
                            <TableCell sx={{ fontWeight: '600', fontSize: 16 }} align="center">{t('eleminate')}</TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ minHeight: 200 }}>
                        {rows.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >


                                <TableCell align="center">{row.serial + 1}</TableCell>
                                <TableCell align="center">{row.pro_name}</TableCell>
                                <TableCell align="center">{row.pro_ip}</TableCell>
                                <TableCell align="center">{row.pro_da}</TableCell>

                                <TableCell align="center" sx={{ color: 'primary' }}>{row.pro_price}</TableCell>
                                <TableCell align="center"><IconButton variant="contained"
                                    onClick={() => luchSwal(row.id)}
                                >

                                    <DeleteForeverIcon color='error' />
                                </IconButton></TableCell>


                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

                <Typography variant='h6' color='primary' sx={{ p: 3 }}>
                    Totale
                </Typography>
                <Typography variant='h6' sx={{ p: 3 }}>
                    {itemsTotal}&euro;
                </Typography>
            </Box>
        </Paper>
    );
}

const styles = {
    button: {

    }
}
