import * as React from 'react';
import * as yup from "yup";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useUserAuth } from 'context/UserAuthContext';
import { LoadingButton } from '@mui/lab';
import swal from 'sweetalert'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Paper } from '@mui/material';




const theme = createTheme();

export default function UpdataPassword() {
    const { t } = useTranslation();
    //Yup Schema
    const schema = yup.object({
        currentPassword: yup.string().trim().min(5, `${t("short_password")}`).required(`${t("password_required")}`),
        password: yup.string().trim().min(5, `${t("short_password")}`).required(`${t("password_required")}`),
        confirmPassword: yup.string().trim().oneOf([yup.ref('password'), null], `${t('password_match')}`),

    }).required();


    const { updatePassword, loading, resStatus, setResStatus } = useUserAuth()
    const navigate = useNavigate()
    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = data => {

        updatePassword(data)
        // console.log(data)
        reset({
            password: '',
            confirmPassword: '',
            currentPassword: ''
        })


    }



    //gender and email section
    // console.log(resStatus.sts)
    if (resStatus.is_sts === 1) {

        swal(
            {
                title: `${"success"}`,
                text: `${resStatus.msg}`,
                icon: 'success',
                buttons:false,
                timer:1000
                //  button:'ok'
            }
        )
        setResStatus('')
        navigate('/login')
    }
    if (resStatus.is_sts === 0) {
        swal(
            {
                title: `${"error"}`,
                text: `${resStatus.msg}`,
                icon: 'error',
                //  button:'ok'
            }
        )
        setResStatus('')
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Paper sx={{ p: 3, marginTop: 8 }}>
                    <Typography variant='h5' color="primary" align='center' sx={{ marginBottom: 4 }}>
                        {t("update_password")}
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>


                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="currentPassword"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="currentPassword"
                                            label={t("current_password")}
                                            type="password"
                                            id="currentPassword"
                                            // autoComplete="new-password"
                                            defaultValue=""
                                            error={errors.currentPassword}
                                            helperText={errors.currentPassword?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="password"
                                            label={t("password")}
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                            defaultValue=""
                                            error={errors.password}
                                            helperText={errors.password?.message}
                                        />}
                                />
                            </Grid>


                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="confirmPassword"
                                            label={t("confirmPassword")}
                                            type="password"
                                            id="confirmPassword"
                                            autoComplete="new-password"
                                            defaultValue=""
                                            error={errors.confirmPassword}
                                            helperText={errors.confirmPassword?.message}
                                        />}
                                />
                            </Grid>


                        </Grid>
                        <LoadingButton
                            loading={loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {t("update")}
                        </LoadingButton>

                    </form>
                </Paper>
            </Container>
        </ThemeProvider>
    );
}

