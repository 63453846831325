import React, { useContext, createContext } from "react";
import axios from 'axios';
// require('dotenv').config()
// console.log(process.env.REACT_APP_URL)
// const BASE_URL="https://inposizione.thewebconcept.com"
// const BASE_URL = "https://link.inposizione.it"
// const BASE_URL = "https://link.inposizione.it"
const BASE_URL = process.env.REACT_APP_URL

const UserAuthContext = createContext()
export default function UserAuthContextProvider({ children }) {
    const [isUserAuthenticated, setIsUserAuthenticated] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [user, setUser] = React.useState('')
    // const [currentUser, setCurrentUser] = React.useState('')
    const [resStatus, setResStatus] = React.useState('')
    const [passStatus, setPassStatus] = React.useState('')
    const [passCode, setPassCode] = React.useState('')
    const [forgetEmail, setForgetEmail] = React.useState('')


    React.useEffect(() => {
        const userData = JSON.parse(window.localStorage.getItem('user'));
        if (userData) {
            setUser(userData)
            // console.log(userData.isU)
            setIsUserAuthenticated(true)
        }
    }, [isUserAuthenticated])


    const registerUser = async (user) => {
        setLoading(true)
        await axios.post(`${BASE_URL}/admin/api/index.php?type=registration`, {
            fullname: user.fullName,
            email: user.email,
            password: user.password,
            phone: user.phoneNo,
            // country: user.country,
            // city: user.city,
            // gender: user.gender,
            role: 'publisher'
        })
            .then(function (response) {
                // console.log(response.data);
                // console.log(response.data.is_sts);
                setResStatus(response.data.is_sts)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
                setResStatus('error')
                setLoading(false)
            });


    }

    //2 Login User


    const loginUser = async (user) => {
        // console.log(user)
        setLoading(true)

        await axios.get(`${BASE_URL}/admin/api/login.php?type=logininfo&username=${user.email}&password=${user.password}&role=${user.role}`)
            .then(function (response) {
                // console.log(response);

                if (response.data.isSuccess === 0)
                    setResStatus('loginError')

                else {
                    setIsUserAuthenticated(true)
                    setLocalStorage({ ...response.data, "isU": true })
                    setResStatus('auth')

                }
                setLoading(false)
            })
            .catch(function (error) {
                // console.log(error);
                setResStatus('timeOut')
                setLoading(false)
            });


    }

    //3 UPDATE Password

    const updatePassword = async (data) => {
        // console.log(data)
        setLoading(true)

        await axios.get(`${BASE_URL}/admin/api/index.php?type=updatepassword&user_id=${user.user_id}&previous_password=${data.currentPassword}&new_password=${data.confirmPassword}`)
            .then(function (response) {
                // console.log(data)
                setResStatus(response.data)
                setLoading(false)
                // console.log(response)
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
            });



    }
    //3 FORGET Password

    const forgetPassword = async (data) => {
        // console.log(data)
        setLoading(true)
        await axios.get(`${BASE_URL}/admin/api/index.php?type=forgetpassword&user_email=${data.email}`)
            .then(function (response) {
                setPassCode(response.data)
                setPassStatus(response.data)
                setLoading(false)
                // console.log(response.data)
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
            });
    }
    // console.log(passStatus)

    //4 CHANGE Password

    const changePassword = async (data) => {
        // console.log(data)
        setLoading(true)
        // await axios.get(`${BASE_URL}/admin/api/index.php?type=changepassword&user_email=imsami67@gmail.com&new_password=11223344`)
        await axios.get(`${BASE_URL}/admin/api/index.php?type=changepassword&user_email=${forgetEmail}&new_password=${data.password}`)
            .then(function (response) {
                // console.log(response)
                setResStatus(response.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
            });
    }





    return (<UserAuthContext.Provider value={{
        isUserAuthenticated, setIsUserAuthenticated, forgetPassword,
        loginUser, registerUser, loading, setLoading, resStatus, setResStatus, user, updatePassword, passStatus, setPassStatus, changePassword, passCode, setPassCode, forgetEmail, setForgetEmail
    }}>

        {children}
    </UserAuthContext.Provider>)

}
export const useUserAuth = () => {
    return useContext(UserAuthContext)
}


const setLocalStorage = (data) => {
    localStorage.setItem('user', JSON.stringify(data));
}