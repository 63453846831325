import * as React from 'react';
import * as yup from "yup";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
// import { useUserAuth } from 'context/UserAuthContext';
import { LoadingButton } from '@mui/lab';
import swal from 'sweetalert'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useArticleContext } from 'context/UserArticle';



const theme = createTheme();

export default function EditInput() {
    const { t } = useTranslation();
    const { approveArticleLoading, approveArticle, setCurrentArticle, currentArticle } = useArticleContext()
    //Yup Schema
    const schema = yup.object({
        publication_link: yup.string().required(`${t("publication_link_required")}`),
        // confirmpublication_link: yup.string().trim().oneOf([yup.ref('publication_link'), null], `${t('publication_link_match')}`),

    }).required();


    // const { updatepublication_link, loading, resStatus, setRevisionRes } = useUserAuth()
    const navigate = useNavigate()
    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = data => {
        // console.log(currentArticle, data.publication_link)
        approveArticle(currentArticle, data.publication_link)



    }



    //gender and email section
    // console.log(resStatus.sts)
    // if (revisionRes.is_sts === 1) {

    //     swal(
    //         {
    //             title: `${"success"}`,
    //             text: `${revisionRes.msg}`,
    //             icon: 'success',
    //             timer: 1000,
    //             buttons: false
    //         }
    //     )
    //     setRevisionRes('')
    //     navigate('/')
    // }
    // if (revisionRes.is_sts === 0) {
    //     swal(
    //         {
    //             title: `${"error"}`,
    //             text: `${revisionRes.msg}`,
    //             icon: 'error',
    //             timer: 1000,
    //             buttons: false
    //         }
    //     )
    //     setRevisionRes('')
    // }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />

                <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>

                    <Controller
                        name="publication_link"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                // required
                                {...field}
                                fullWidth
                                multiline={true}
                                rows={4}
                                name={t('publication_link')}
                                label={t("publication_link")}
                                type="publication_link"
                                id="publication_link"
                                // autoComplete="new-publication_link"
                                defaultValue=""
                                error={errors.publication_link}
                                helperText={errors.publication_link?.message}
                            />}
                    />

                    <LoadingButton
                        loading={approveArticleLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {t("approve")}
                    </LoadingButton>

                </form>

            </Container>
        </ThemeProvider>
    );
}

