import React from 'react'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useNavigate } from 'react-router-dom';
import { IconButton, Paper } from '@mui/material';
import AddNetworkForm from './AddNetworkForm';
export default function AddNetwork() {
    const navigate = useNavigate()
    const [product,setProduct]=React.useState('')
    return (
        <Paper elevation={2} sx={{ p: 2 }}>
            <IconButton variant="outlined"

                onClick={
                    () => navigate('/products' )
                }>
                <KeyboardReturnIcon />

            </IconButton>

            <AddNetworkForm product={product} setProduct={setProduct} />

        </Paper>
    )
}
