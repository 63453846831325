import { Box } from '@mui/system'
import React from 'react'
import ForgetPassword from './ForgetPassword'
import ChangePassword from './ChangePassword'
import VerifyEmail from './VerifyEmail'
import { LoadingButton } from '@mui/lab'
export default function ForgetPasswordNavigator() {
    const [screen, setScreen] = React.useState(0)
    return (
        <Box>

            {(() => {

                switch (screen) {
                    case 0:
                        return <ForgetPassword setScreen={setScreen} />

                    case 1:
                        return <VerifyEmail setScreen={setScreen} />

                    case 2:
                        return < ChangePassword setScreen={setScreen} />


                    default: return <ForgetPassword />
                }
            })()}

        </Box>
    )
}
