import * as React from 'react';
// import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';

import RateReviewIcon from '@mui/icons-material/RateReview';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from "react-i18next";
import { Typography, useTheme } from '@mui/material';
// import { makeStyles, withStyles } from "@material-ui/styles";
import { makeStyles, withStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';
import LogoutIcon from '@mui/icons-material/Logout';
import ListAltIcon from '@mui/icons-material/ListAlt';
import swal from 'sweetalert';
import { useUserAuth } from '../../../context/UserAuthContext';
// const useStyles = makeStyles((theme) => ({
//     root: {
//         width: "100%",
//         maxWidth: 360,
//         backgroundColor: theme.palette.background.paper
//     }
// }));



// export default function ListItems({ setCurrentScreen, currentScreen }) {
export default function ListItems() {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const { setIsUserAuthenticated } = useUserAuth()
    const location = useLocation()
    const theme = useTheme()

    React.useEffect(() => {
        // console.log(location)
    }, [location])

    const navigation = (link) => {
        navigate(link)
    }

    const style = {
        listButton: {
            width: '5.3rem',
            display: 'flex', flexDirection: 'column',
            justifyContent: 'center', alignItems: 'center', borderRadius: 5,
            // marginLeft:2
        }
    }

    const ListItemButton = withStyles({
        root: {
            // "&$selected": {
            //     backgroundColor: "red",
            //     color: "white",
            //     "& .MuiListItemIcon-root": {
            //         color: "white"
            //     }
            // },
            "&$selected:hover": {
                backgroundColor: "purple",
                color: "white",
                "& .MuiListItemIcon-root": {
                    color: "white"
                }
            },

            // "&:hover": {
            //     backgroundColor: theme.palette.primary.light,
            //     color: theme.palette.text.secondary,
            //     "& .MuiListItemIcon-root": {
            //         color: theme.palette.text.primary
            //     }
            // }
        },
        selected: {}
    })(ListItem);


    //HANDLE LOGOUT
    const handleLogout = () => {


        swal({
            title: `${t("sure_logout")}`,
            // text: "Log Out!",
            icon: 'warning',
            buttons: true,
            // confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            dangerMode: true,
            buttons: true
        }).then((result) => {

            if (result) {
                setIsUserAuthenticated(false)
                localStorage.removeItem('user')
                navigate('/')

                swal(
                    `${t("logout")}`,

                    `${t("logout_success")}`
                )
            }
            else {
                swal(

                    `${t("welcome_back")}`
                )
            }
        })
    }
    return (

        <React.Fragment>
            <ListItemButton

                sx={[style.listButton, { backgroundColor: location.pathname == '/' ? theme.palette.primary.main : 'none', }]}

                disableRipple
                onClick={() => {
                    navigation('/')
                }} >
                <ListItemIcon >
                    <HomeIcon sx={{ fo1ntSize: 30, margin: 'auto', color: location.pathname === '/' ? 'white' : 'none', }} />
                </ListItemIcon>
                <Typography sx={{ cursor: 'default', fontSize: 10, color: location.pathname === '/' ? 'white' : 'none' }} >{t('dashboard')}</Typography>
            </ListItemButton>




            <ListItemButton
                sx={[style.listButton, { backgroundColor: location.pathname == '/profile' ? theme.palette.primary.main : 'none' }]}
                disableRipple
                onClick={() => {
                    navigate('/profile')
                }} >
                <ListItemIcon>
                    <PersonIcon sx={{ color: location.pathname === '/profile' ? 'white' : 'none', fontSize: 30, margin: 'auto' }} />
                </ListItemIcon>
                <Typography sx={{ cursor: 'default', fontSize: 10, color: location.pathname === '/profile' ? 'white' : 'none' }} >{t('profile')}</Typography>
            </ListItemButton>


            <ListItemButton
                sx={[style.listButton, { backgroundColor: location.pathname == '/products'||location.pathname === '/addNetwork' ? theme.palette.primary.main : 'none' }]}
                disableRipple
                onClick={() => {
                    navigate('/products')
                }}>
                <ListItemIcon>
                    <CategoryIcon sx={{ color: location.pathname === '/products'||location.pathname === '/addNetwork' ? 'white' : 'none', fontSize: 30, margin: 'auto' }} />
                </ListItemIcon>
                <Typography sx={{ cursor: 'default', fontSize: 10, color: location.pathname === '/products'||location.pathname === '/addNetwork' ? 'white' : 'none' }} >{t('products')}</Typography>
            </ListItemButton>


            <ListItemButton
                sx={[style.listButton, { backgroundColor: location.pathname === '/orders' ? theme.palette.primary.main : 'none' }]}
                disableRipple
                onClick={() => {
                    navigate('/orders')
                }} >
                <ListItemIcon>
                    <ShoppingBagIcon sx={{ color: location.pathname === '/orders' ? 'white' : 'none', fontSize: 30, margin: 'auto' }} />
                </ListItemIcon>
                <Typography sx={{ cursor: 'default', fontSize: 10, color: location.pathname === '/orders' ? 'white' : 'none' }} >{t('orders')}</Typography>
            </ListItemButton>


            <ListItemButton
                sx={[style.listButton, { backgroundColor: location.pathname == '/completedOrders' ? theme.palette.primary.main : 'none' }]}
                disableRipple
                onClick={() => {
                    navigate('/completedOrders')
                }} >
                <ListItemIcon>
                    <ListAltIcon sx={{ color: location.pathname === '/completedOrders' ? 'white' : 'none', fontSize: 30, margin: 'auto' }} />
                </ListItemIcon>
                <Typography sx={{ cursor: 'default', fontSize: 10, color: location.pathname === '/completedOrders' ? 'white' : 'none' }} >{t('completed_orders')}</Typography>
            </ListItemButton>




            {/* <ListItemButton
                sx={[style.listButton, { backgroundColor: location.pathname == '/article' || location.pathname === '/editor' ? theme.palette.primary.main : 'none' }]}
                disableRipple
                onClick={() => {
                    navigate('/article')
                }} >
                <ListItemIcon>
                    <RateReviewIcon sx={{ color: location.pathname === '/article' || location.pathname === '/editor' ? 'white' : 'none', fontSize: 30, margin: 'auto' }} />
                </ListItemIcon>
                <Typography sx={{ cursor: 'default', fontSize: 10, color: location.pathname === '/article' || location.pathname === '/editor' ? 'white' : 'none' }} >{t('article')}</Typography>
            </ListItemButton> */}


            <ListItemButton
                sx={[style.listButton, { backgroundColor: location.pathname == '/settings' ? theme.palette.primary.main : 'none' }]}
                disableRipple
                onClick={() => {
                    navigate('/settings')
                }} >
                <ListItemIcon>
                    <SettingsIcon sx={{ color: location.pathname === '/settings' ? 'white' : 'none', fontSize: 30, margin: 'auto' }} />
                </ListItemIcon>
                <Typography sx={{ cursor: 'default', fontSize: 10, color: location.pathname === '/settings' ? 'white' : 'none' }} >{t('settings')}</Typography>
            </ListItemButton>
            <ListItemButton
                sx={[style.listButton, { color: 'red', marginTop: 5 }]}

                onClick={() => {

                    handleLogout()


                }} >
                <ListItemIcon>
                    <LogoutIcon sx={{ color: 'red', fontSize: 30, margin: 'auto' }} />
                </ListItemIcon>
                <Typography sx={{ cursor: 'default', fontSize: 10, textAlign: 'center' }} >{t('logout')}</Typography>
            </ListItemButton>
        </React.Fragment>
    )
}

