import React from 'react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useLocation } from "react-router-dom";
import { useUserAuth } from "context/UserAuthContext";
import { Grid, Typography } from "@mui/material";
import swal from 'sweetalert'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LogoIcon from '../../../assets/img/logo.png'
import { Box } from "@mui/material";
import Paper from '@mui/material/Paper';





export default function ChangePassword({ setScreen }) {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { changePassword, resStatus, loading, setResStatus, forgetEmail } = useUserAuth()



  //validation Schema
  const schema = yup.object({
    // email: yup.string().email(`${t("invalid_email")}`).required(`${t("email_required")}`),
    password: yup.string().trim().required(`${t("password_required")}`),
    confirmPassword: yup.string().trim().oneOf([yup.ref('password'), null], `${t('password_match')}`),
  }).required();


  const { handleSubmit, control, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });



  if (resStatus.is_sts === 0) {

    swal(
      {
        title: `${t("error")}`,
        text: `${t("error")}`,
        icon: 'error',
        //  button:'ok'
      }
    )
    setResStatus('')

  }
  if (resStatus.is_sts === 1) {

    swal(
      {
        title: `${t("success")}`,
        // text: `${t("password_send_to_email")}`,
        icon: 'success',
        buttons: false,
        timer: 1000
      }
    )
    // setScreen(1)
    navigate('/login')
    setResStatus('')
  }

  const onSubmit = data => {

    changePassword({...data,email:forgetEmail})


  }



  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>

        <img
          src={LogoIcon}
          style={{
            width: '19rem',
            height: '5rem',
          }}
        />
      </Box>

      <Paper elevation={3} sx={{
        display: 'flex', flexDirection: 'column', width: 345, margin: 'auto',
        p: 5
      }}>
        <Typography variant="h5" align="center">
          {t('change_password')}
        </Typography>


        <Controller
          name="email"
          control={control}
          render={({ field }) =>
            <TextField
              {...field}
              error={errors.email}
              id="margin-dense"
              margin="normal"
              label={t("email")}
              disabled
              defaultValue={forgetEmail}
            // autocomplete="off"

            // helperText={errors.email?.message}

            />
          }
        />
        <Grid container spacing={2}>


          <Grid item xs={12} sm={12}>
            
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              name="password"
              control={control}
              render={({ field }) =>
                <TextField
                  // required
                  {...field}
                  fullWidth
                  name="password"
                  label={t("password")}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  defaultValue=""
                  error={errors.password}
                  helperText={errors.password?.message}
                />}
            />
          </Grid>


          <Grid item xs={12} sm={12}>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) =>
                <TextField
                  // required
                  {...field}
                  fullWidth
                  name="confirmPassword"
                  label={t("confirmPassword")}
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  defaultValue=""
                  error={errors.confirmPassword}
                  helperText={errors.confirmPassword?.message}
                />}
            />
          </Grid>


        </Grid>

        <LoadingButton loading={loading}
          style={{ width: '6.5rem', margin: 'auto', marginTop: "1rem" }}
          variant="contained"
          type="submit"
        // onClick={() => setScreen(0)}
        >

          {t('send')}
        </LoadingButton>



        <Box sx={{ display: 'flex', fontSize: 16, justifyContent: 'center', marginTop: 5 }}>

          <Link to="/login"  >
            {t("login")}
          </Link>
        </Box>

        {/* <Box sx={{ fontSize: 14, alignSelf: 'center', marginTop: 4 }}>

          <Link to="/"  >
            {t("back_to_home")}
          </Link>
        </Box> */}

      </Paper>
    </form>

  );
}
