import React from 'react'
import { Box } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useOrderContext } from 'context/OrderContext'

export default function FileOrder() {
  const { currentOrderDetails } = useOrderContext()
  const { t } = useTranslation()
  // console.log(currentOrderDetails)
  return (
    <Box sx={{ padding: 3 }}>
      <Grid container alignItems='center' spacing={1}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6">
            {t('file')}
          </Typography>
          <Typography variant="body2">
            <a style={{ cursor: 'pointer', color: 'primary' }} href={currentOrderDetails.item_img} target="_blank">Visualizza l'articolo caricato</a>          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6">
            {t('publication_date')}
          </Typography>
          <Typography variant="body2">
            {currentOrderDetails.expirydate !== null ? currentOrderDetails.expirydate : 'null'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6">
            {t("further_info")}
          </Typography>
          <Typography variant="body2">
            {currentOrderDetails.pro_additionalnotes}
          </Typography>

        </Grid>


      </Grid>

    </Box>
  )
}
