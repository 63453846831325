import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useProductContext } from 'context/ProductsContext';
import { IconButton, Typography } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useCartContext } from 'context/CartContext';
import PopUp from 'components/PopUp/PopUp';
import { useFilterContext } from 'context/FilterContex';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
// import DetailsPopUp from 'components/PopUp/DetailsPopUp';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
// import LinearProgressBar from 'components/Progress Bar/LinearProgressBar';
import Detail from 'components/PopUp/ShowOrderDetails/Detail';
import { Button } from '@mui/material';
import Spinner from 'components/Spinner';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export default function PaginationTable() {
    const [page, setPage] = React.useState(0);
    const { loadingProducts, products } = useProductContext()
    const { filterdPro, setStatus, status, loading } = useFilterContext()
    const [search, setSearch] = React.useState(products)
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const modalRef = React.useRef(null)
    const detailRef = React.useRef(null)
    const theme = useTheme()
    const navigate = useNavigate()
    const { setCurrentItem } = useCartContext()
    const { t } = useTranslation()

    React.useEffect(() => {
        setStatus('')
    }, [products])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRef = () => {
        modalRef.current.click()
    }
    const handleDetailRef = () => {
        detailRef.current.click()
    }


    const handleSearch = (text) => {

        if (text) {
            const newData = products.filter(item => {
                const itemData = item.pro_name ? item.pro_name.toUpperCase() : ''.toUpperCase()
                const textData = text.toUpperCase()
                return itemData.indexOf(textData) > -1;

            })
            setSearch(newData)


        }
        // if (text) {
        //     setSearch(search.filter(item => item.pro_name.toLowerCase().include(text.toLowerCase())))
        // }
        else {
            setSearch(products)
            // console.log("there")
        }
    }


    // console.log(typeof products)
    //Creat data for row
    function createData(serial, id, pro_name, pro_ip, pro_da, pro_backlink, pro_za, pro_gnews, pro_gambling, pro_price, pro_redazione, pro_note, pro_examplepublication, pro_additionalnotes, status) {
        return { serial, id, pro_name, pro_ip, pro_da, pro_backlink, pro_za, pro_gnews, pro_gambling, pro_price, pro_redazione, pro_note, pro_examplepublication, pro_additionalnotes, status };
    }


    const rows =
        search.map((item, i) => {

            return createData(i, item.id, item.pro_name, item.pro_ip, item.pro_da, item.pro_backlink, item.pro_za, item.pro_gnews, item.pro_gambling, item.pro_price, item.pro_redazione, item.pro_note, item.pro_examplepublication, item.pro_additionalnotes, item.status)

        })


    return (
        <>
            {loadingProducts ?
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <Spinner />
                </Box>


                :
                <Paper sx={{ width: '100%', overflow: 'hidden', position: 'relative' }}>

                    <TableContainer  >
                        <Box sx={{ zIndex: 999, right: 20, top: 7, width: '100%', display: 'flex', alignItems: "center", justifyContent: 'space-around', padding: 2, flexWrap: 'wrap' }}>
                            <Box sx={{ width: '80%' }}>

                                <input type='text'
                                    style={{
                                        border: `2px solid ${theme.palette.primary.main}`, padding: 10,
                                        borderRadius: 5, width: '100%', margin: 'auto', display: `${products.length < 1 ? 'none' : 'flex'}`,
                                    }}
                                    placeholder={t('search_name')}
                                    onChange={(e) => handleSearch(e.target.value)} />
                                <Typography variant='h6'  align='center' sx={{ display: `${!products.length < 1 ? 'none' : 'flex'}`, p: 5 }}>
                                    {t('no_item')}
                                </Typography>

                            </Box>
                            <Box>

                                <Button variant="contained"
                                    sx={{ width: '100%' }}
                                    onClick={
                                        () => navigate('/addNetwork')
                                    }
                                    endIcon={<AddIcon />}>
                                    {t('add_network')}
                                </Button>
                            </Box>
                        </Box>
                        <Table stickyHeader aria-label="sticky table" sx={{ display: `${products.length < 1 ? 'none' : ''}`, marginTop: 5 }}>
                            <TableHead >
                                <TableRow >

                                    <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} ># </TableCell>
                                    <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center" >SITO WEB </TableCell>
                                    <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center" >IP</TableCell>
                                    <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center">DA</TableCell>
                                    <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black', width: '10rem' }} align="center">BACKLINK</TableCell>
                                    <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center">ZA</TableCell>
                                    <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black', width: '7rem' }} align="center">G NEWS</TableCell>
                                    <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center">{t('price')}</TableCell>
                                    <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center">REDAZIONE</TableCell>
                                    <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center">DETTAGLI</TableCell>
                                    <TableCell sx={{ fontWeight: '600', fontSize: 16, color: 'black' }} align="center">{t('publication_status')}</TableCell>



                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {

                                    rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, i) => {
                                            return (
                                                <TableRow
                                                    key={i}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >


                                                    <TableCell align="center">{row.serial + 1}</TableCell>
                                                    <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 15 }}>

                                                        <a href={`https://${row.pro_name}`} target="_blank">{row.pro_name}</a>


                                                    </TableCell>
                                                    <TableCell align="center">{row.pro_ip}</TableCell>
                                                    <TableCell align="center">{row.pro_da}</TableCell>
                                                    <TableCell align="center">{row.pro_backlink}</TableCell>
                                                    <TableCell align="center">{row.pro_za}</TableCell>
                                                    <TableCell align="center">{row.pro_gnews}</TableCell>



                                                    <TableCell align="center" sx={{ color: 'primary' }}>{row.pro_price}</TableCell>
                                                    <TableCell align="center">{row.pro_redazione}</TableCell>


                                                    {/* DETAILS TABLE CELL */}

                                                    <TableCell align="center"><IconButton variant="contained"
                                                        onClick={() => {

                                                            handleDetailRef()
                                                            setCurrentItem(row)
                                                        }
                                                        }
                                                    >

                                                        <InfoIcon color='primary' />
                                                    </IconButton></TableCell>

                                                    {/* CART LOGO TABLE CELL */}
                                                    <TableCell align="center" sx={{ alignItems: 'center' }}>

                                                        {
                                                            row.status === '1' ?
                                                                <CheckCircleIcon sx={{ color: '#64dd17', mt: '.4rem' }} /> : <CancelIcon sx={{ color: '#e64a19', mt: '.4rem' }} />
                                                        }

                                                    </TableCell>


                                                </TableRow>
                                            );
                                        })}
                            </TableBody>
                        </Table>
                        <PopUp ref={modalRef} />

                        <Detail ref={detailRef} />
                        {/* <DetailsPopUp ref={detailRef} /> */}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{ display: `${products.length < 1 ? 'none' : ''}` }}
                    />
                </Paper>}</>
    );
}
