import * as React from 'react';
import * as yup from "yup";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useAgencyContext } from 'context/AgencyContext';
import { useTranslation } from 'react-i18next';
import { useUserAuth } from 'context/UserAuthContext';


const theme = createTheme();

export default function PrivateForm() {
    const { agencyData, agencyDataUpdata, agencyDataSubmit,isLoading } = useAgencyContext()
    const { t } = useTranslation()
    const { user } = useUserAuth()

    //Yup Schema
    const schema = yup.object({

        name: yup.string().required("Name required"),
        lastName: yup.string().required("Name required"),
        city: yup.string().trim().min(3, "Too short City name").required("City required"),
        village: yup.string().trim().required("City required"),
        proviance: yup.string().trim().required("City required"),
        address: yup.string().trim().required("City required"),
        cap: yup.string().trim().min(3, "Too short City name").required("City required"),
        codiceFiscale: yup.string().trim().required("City required"),
    }).required();

 
    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = data => {
        // setStorage({ ...data, type: 'private', user_id: user.user_id })
        // setAgencyData({ ...data, type: 'private', user_id: user.user_id })
        //condition to add or update agency data
        if (agencyData===null || agencyData.is_sts===0) {
            agencyDataSubmit({ ...data, type: 'private', user_id: user.user_id })
        }
        else if(agencyData.is_sts===1)
            agencyDataUpdata({ ...data, type: 'private', user_id: user.user_id, user_agency_id: agencyData.user_agency_id })


        reset({
            name: '',
            city: '',
            village: '',
            address: '',
            cap: '',
            codiceFiscale: '',
            lastName: '', proviance: ''

        })
    }
    //submit Api


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" >
                <CssBaseline />
                <Box
                    sx={{
                        // marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >


                    <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="name"
                                            // required
                                            fullWidth
                                            id="name"
                                            label={t("name")}
                                            defaultValue=""
                                            error={errors.name}
                                        // helperText={errors.name?.message}
                                        />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="lastName"
                                            // required
                                            fullWidth
                                            id="lastName"
                                            label={t('lastName')}
                                            defaultValue=""
                                            error={errors.lastName}
                                        // helperText={errors.name?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="codiceFiscale"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="codiceFiscale"
                                            // required
                                            fullWidth
                                            id="codiceFiscale"
                                            label="
                                            Codice Fiscale"
                                            defaultValue=""
                                            error={errors.codiceFiscale}
                                        // helperText={errors.name?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Controller
                                    name="address"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="address"
                                            // required
                                            fullWidth
                                            id="address"
                                            label={t('address')}
                                            defaultValue=""
                                            error={errors.address}
                                        // helperText={errors.name?.message}
                                        />}
                                />
                            </Grid>




                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="city"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            id="city"
                                            label="Citta"
                                            name="city"
                                            autoComplete="family-name"
                                            defaultValue=""
                                            error={errors.city}
                                        // helperText={errors.city?.message}
                                        />}
                                />
                            </Grid>






                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="cap"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="cap"
                                            label="CAP"
                                            name="cap"
                                            autoComplete="family-name"
                                            defaultValue=""
                                            error={errors.cap}
                                        // helperText={errors.village?.message}
                                        />} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="village"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="village"
                                            label={t('village')}
                                            name="village"
                                            // autoComplete="email"
                                            defaultValue=""
                                            error={errors.village}
                                        // helperText={errors.email?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="proviance"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="proviance"
                                            label={t('proviance')}
                                            name="proviance"
                                            // autoComplete="email"
                                            defaultValue=""
                                            error={errors.proviance}
                                        // helperText={errors.email?.message}
                                        />}
                                />
                            </Grid>



                        </Grid>
                        <LoadingButton
                            type="submit"
                            loading={isLoading}
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {agencyData === null ? `${t("add")}` : `${t("edit")}`}
                        </LoadingButton>

                    </form>
                </Box>
                {/* <Copyright sx={{ mt: 5 }} /> */}
            </Container>
        </ThemeProvider>
    );
}