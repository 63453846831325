import React, { useContext, createContext } from "react";
import axios from 'axios';
// const BASE_URL="https://inposizione.thewebconcept.com"
// const BASE_URL="https://link.inposizione.it"
const BASE_URL = process.env.REACT_APP_URL

const AgencyContext = createContext()
export default function AgencyContextProvider({ children }) {
    const [agencyData, setAgencyData] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(false)
    const [agencyRes, setAgencyRes] = React.useState('')
    const [authAgency, setAuthAgency] = React.useState(false)
    const [loadAgency, setLoadAgency] = React.useState(false)
    const [show, setShow] = React.useState(false)


    const user = JSON.parse(window.localStorage.getItem('user'))
    // console.log(user.user_id)
    // const [cartDetails, setCartDetails] = React.useState('')
    React.useEffect(() => {
        if (user !== null) {
            getUserAgency()

            // setAgencyData(JSON.parse(window.localStorage.getItem('agency')))
            setAuthAgency(false)
            setLoadAgency(false)
        }
    }, [authAgency, loadAgency])


    //GET USER AGENCY DATA USING API
    const getUserAgency = async () => {

        await axios.post(`${BASE_URL}/admin/api/index.php?type=user_agency_list&user_id=${user.user_id}`)
            .then(function (response) {

                // console.log(response.data)
                if (response.data.is_sts === 1) {

                    setAgencyData(response.data)
                    localStorage.setItem('agency', JSON.stringify(response.data))
                }

            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }



    //Add Agency data using Api
    const agencyDataSubmit = async (formData) => {

        setIsLoading(true)
        await axios.post(`${BASE_URL}/admin/api/index.php?type=user_agency`, formData)
            .then(function (response) {


                // console.log(response)
                if (response.data.is_sts === 0) {
                    setAgencyRes("error")

                    setIsLoading(false)
                }
                else if (response.data.is_sts === 1) {

                    setAgencyRes("success")
                    setAgencyData(response.data)
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                setAgencyRes("error")
                console.log(error);
                setIsLoading(false)
            })


    }

    //EDIT AGENCY DATA
    const agencyDataUpdata = async (formData) => {
        setIsLoading(true)
        await axios.post(`${BASE_URL}/admin/api/index.php?type=user_agency`, formData)

            .then(function (response) {

                if (response.data.is_sts === 0) {
                    // console.log(response.data)
                    setAgencyRes("error")
                    // console.log('working not')
                    setIsLoading(false)

                }
                else if (response.data.is_sts === 1)
                    // setAgencyData(data)
                    setAgencyRes("success")
                setIsLoading(false)
                // console.log(response.data)
                setLoadAgency(true)

            })
            .catch(function (error) {
                setAgencyRes("error")
                setIsLoading(false)
                console.log(error);
            })

    }

    return (<AgencyContext.Provider value={{
        agencyData, setAgencyData, agencyDataSubmit, agencyDataUpdata, isLoading,
        setIsLoading, agencyRes, setAgencyRes, setAuthAgency, setLoadAgency,show, setShow
    }}>
        {children}
    </AgencyContext.Provider>)

}
export const useAgencyContext = () => {
    return useContext(AgencyContext)
}