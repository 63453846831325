import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import EditPopUp from 'components/PopUp/EditPopUp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useArticleContext } from 'context/UserArticle';
import { IconButton } from '@mui/material';
import striptags from 'striptags';

import swal from 'sweetalert';

export default function CkEditor({ data }) {
    // console.log(data.writing_text)
    let initialState = "<p></p>"
    if (data.writing_text != null || data.writing_text != undefined) {

        const obj = JSON.parse(data.writing_text)
        const arr = Object.entries(obj).map(([key, value]) => value);
        initialState = new TextDecoder().decode(new Uint8Array(arr))
    }
    const [content, setContent] = React.useState(initialState)
    // const [content, setContent] = React.useState('<p>Enter text here</p>')
    const [called, setCalled] = React.useState(false)
    const { t } = useTranslation()
    const { approveArticleLoading, approveArticle, setCurrentArticle } = useArticleContext()

    const editRef = React.useRef(null)
    const handleEditRef = () => {
        editRef.current.click()
    }


    const triggerAlert = () => {
        swal({
            title: "Text Copied!",
            text: " ",
            icon: "success",
            timer: 1000,
            buttons: false
        })
    }
  
    return (

        <>
            <Box >

                <LoadingButton
                    loading={approveArticleLoading}
                    // disabled={data.writer_sts === 'approvedarticle'}
                    onClick={() => {
                        handleEditRef()
                        setCurrentArticle(data.order_id)
                        // console.log(data.order_id)
                    }}
                    sx={{ marginRight: 1 }}
                    variant='contained'>{t('approve')}
                </LoadingButton>


                {/* <LoadingButton
                    loading={approveArticleLoading}
                    disabled={data.writer_sts === 'approvedarticle'}
                    onClick={() => {
                        approveArticle(data.order_item_id)
                    }}
                    sx={{ position: 'absolute', right: 40 }}
                    variant='contained'>{t('approve')}
                </LoadingButton> */}


                <EditPopUp ref={editRef} />

            </Box>
            <Box sx={{ position: 'relative' }}>


                <Typography variant='h6' align='center'>
                    {t('edit_text')}
                </Typography>
                <Box>

                    <IconButton color='primary'
                        sx={{ position: 'absolute', right: 10, zIndex: 99 }}
                        onClick={() => {
                            // const plainText = htmlToText(html);
                            const plainText = striptags(content);
                            navigator.clipboard.writeText(plainText)
                            triggerAlert()
                        }}
                    >
                        <ContentCopyIcon />
                    </IconButton>


                    <CKEditor
                        editor={ClassicEditor}
                        config={{
                            readOnly: true
                        }}
                        data={content}
                        disabled
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            // console.log({ event, editor, data });
                            // console.log(data);
                            setContent(data)
                        }}

                        onFocus={(event, editor) => {
                            if (called) {
                                return;
                            }
                            setCalled(true)
                            data.writing_text === null && setContent('')

                        }}



                    />

                </Box>



            </Box>
        </>
    )
}
