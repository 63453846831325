import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from "react-i18next";
import ModeIcon from '@mui/icons-material/Mode';
import { useUserOrderContext } from 'context/UserOrderContext';
// import OrderDetailPopUp from '../../Components/PopUp/DetailsPopUp';
import OrderDetailPopUp from 'components/PopUp/OrderDetailPopUp';
import { IconButton } from '@mui/material';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
// import { useOrderContext } from '../../../context/OrderContext';
import { useNavigate } from 'react-router-dom';
// import Note from 'Source/Components/PopUp/Note.js';
import Note from 'components/PopUp/Note.js';
import { useOrderContext } from 'context/OrderContext';
import Spinner from 'components/Spinner';

//Creat data for row
function createData(serial, order_id, link_publish, name_domain, review, expirydate, order_sts, writing_text, description, destinationLink, furtherform, sito_web, pro_name, pro_examplepublication, pro_additionalnotes, total,item_img,ordertype,anchorText) {
  return { serial, order_id, link_publish, name_domain, review, expirydate, order_sts, writing_text, description, destinationLink, furtherform, sito_web, pro_name, pro_examplepublication, pro_additionalnotes, total ,item_img,ordertype,anchorText};
}


// const userData = JSON.parse(window.localStorage.getItem('user'));

export default function CompletedOrders() {

  const { userOrders, getOrderLoading } = useUserOrderContext()
  const { newOrder, setCurrentOrderDetails } = useOrderContext()
  // const {setCurrentOrder}=useCartContext()
  const { t } = useTranslation()
  const navigate = useNavigate()

  //RELOAD API ON CHANGE ANY VALUE

  //GENERATE ROWS FOR TABLE
  const rows =
    userOrders.filter((order => order.order_sts === 'published')).map((item, i) => {

      return createData(i, item.order_id, item.link_publish, item.name_domain, item.review, item.expirydate,
        item.order_sts, item.writing_text, item.description, item.destinationLink, item.furtherform, item.sito_web, item.pro_name, item.pro_examplepublication, item.pro_additionalnotes, item.total,item.item_img,item.ordertype,item.anchorText)

    })
  // console.log(userOrders)

  //DETAIL MODAL REF
  const detailRef = React.useRef(null)
  const handleDetailRef = () => {
    detailRef.current.click()
  }
  const noteRef = React.useRef(null)
  const handleNoteRef = () => {
    noteRef.current.click()
  }

  const edit = () => {

  }

  return (
    <>{getOrderLoading ?

      // <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      //   <Typography variant='subtitle1' align='center' sx={{ marginTop: '15rem' }}>
      //     {t('empty')}
      //   </Typography>
      // </Box>

      <Spinner />

      : <div>
        {/* <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>

          <CircularProgress sx={{ display: `${ordersLoading ? 'block' : 'none'}` }} />
        </Box> */}

        <Box sx={{ display: `${userOrders.length < 1 ? 'flex' : 'none'}`, alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant='subtitle1' align='center' sx={{ marginTop: '15rem' }}>
            {t('empty')}
          </Typography>
        </Box>


        <TableContainer component={Paper} sx={{ display: `${!userOrders.length < 1 ? 'block' : 'none'}` }}>

          <Table sx={{ worder_idth: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('order_number')}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('web_site')}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('domain_name')}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('order_start_date')}</TableCell>
                {/* <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("additional_note")}</TableCell> */}
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("amount")}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("order_status")}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("order_detail")}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("view_article")}</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >

                  <TableCell align="center">{row.order_id}</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 14 }}>

                    <a href={`https://${row.link_publish}`} target="_blank">{row.link_publish}</a>


                  </TableCell>
                  <TableCell align="center">{row.name_domain}</TableCell>
                  <TableCell align="center">{row.expirydate}</TableCell>
                  {/* <TableCell align="center">
                    <IconButton variant="contained"
                      onClick={() => {

                        handleNoteRef()
                        setCurrentOrder(row)
                        console.log(row)
                      }}>

                      <StickyNote2Icon color='primary' />
                    </IconButton>
                  </TableCell> */}
                  <TableCell align="center" sx={{ color: 'primary' }}>
                    {/* <a href={`https://${row.sito_web}`} target="_blank"
                      style={{ textDecoration: 'none' }}
                    >
                    </a> */}
                      {row.total}
                  </TableCell>

                  <TableCell align="center">
                    {/* <div style={{ padding: 1, backgroundColor: '#e3f2fd' */}
                    <div style={{
                      padding: 5, backgroundColor:
                        `${row.order_sts === 'pending' ? '#fff75e'
                          : row.order_sts === "" ? "#fff75e"
                            : row.order_sts === 'published' ? '#81c784'
                            : "#f38375"}`
                      , borderRadius: '12px', fontSize: 12, fontWeight: 'bold'
                    }}>

                      {t(row.order_sts)}
                    </div>

                  </TableCell>
                  <TableCell align="center">
                    <IconButton variant="contained"
                      onClick={() => {

                        handleDetailRef()
                        setCurrentOrderDetails(row)
                        // console.log(row)

                      }
                      }
                    >

                      <InfoIcon color='primary' />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton variant="contained"
                      onClick={() => {

                        navigate('/editor', { state: { data: row } })
                        // console.log(row)
                      }
                      }
                    >

                      <StickyNote2Icon color='primary' />
                    </IconButton>
                  </TableCell>




                </TableRow>
              ))}
            </TableBody>
          </Table>

        </TableContainer>

        <OrderDetailPopUp ref={detailRef} />
        <Note ref={noteRef} />
      </div>
    }
    </>
  );
}

