import { Box } from '@mui/material'
import React from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CartInputForm from './CartInputForm';
import CartFileUpload from './CartFileUpload';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
export default function CartFormPagination({ setOpen }) {

    const [currentScreen, setCurrentScreen] = React.useState('details')
    const { t } = useTranslation()
    return (
        <Box>
            {/* <Box sx={{ backgroundColor: '#1976D2', color: 'white', p: 2, borderRadius: 1 }}>
                Fatturazione
            </Box> */}


            <Box sx={{ display: 'flex', marginTop: 1, justifyContent: 'center' }}>
                <ListItemButton onClick={() => setCurrentScreen('file')}
                    sx={{
                        backgroundColor: `${currentScreen === 'file' ? '#1976D2':"rgb(224,224,224)" }`,
                        color: `${currentScreen === 'file' && 'white'}`, borderRadius: 1, textAlign: 'center',width:'2rem'
                    }}
                    disableRipple
                >

                    <ListItemText primary={t('file')} />
                </ListItemButton>
                <ListItemButton onClick={() => setCurrentScreen('details')}
                    sx={{
                        backgroundColor: `${currentScreen === 'details' ? '#1976D2':"rgb(224,224,224)" }`,
                        color: `${currentScreen === 'details' && 'white'}`, borderRadius: 1,
                         textAlign: 'center',width:'2rem'
                    }}
                    disableRipple
                >

                    <ListItemText primary={t('detail')} />
                </ListItemButton>
            </Box>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4,}}>


                {(() => {

                    switch (currentScreen) {
                        case 'file':
                            return <CartFileUpload setOpen={setOpen} />
                        case 'details':
                            return <CartInputForm setOpen={setOpen} />


                        default: return <CartInputForm />
                    }
                })()}

            </Container>

        </Box>
    )
}
